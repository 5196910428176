<template>
	<div class="MicroClass">
		<div class="row clearfix displayFlex" id="clearfix">
			<Nav_class></Nav_class>
			<div style="margin: 0 15px;flex: 1 1 auto;overflow: auto;">
				<div class="class_study_con displayFlex justifyContentSpaceBetween">
					<div class="class_study_con_base">
						<el-radio-group v-model="radio1">
					      <el-radio-button label="微课列表"></el-radio-button>
						  <el-radio-button label="视频列表"></el-radio-button>
						</el-radio-group>
						<div>
							<el-radio-group v-if="radio1=='视频列表'" size="small" v-model="radio2">
							  <el-radio-button :label="item.mainKind" v-for="item in mainKindlist">{{item.name}}</el-radio-button>
							</el-radio-group>
						</div>
					</div>
					<div class="class_study_con_base">
						<span style="margin-right: 10px;" @click="init()">
							<a @click="">发起微课</a>
						</span>
					</div>
				</div>
				<div v-if="radio1=='微课列表'">
					<BaseTable :tableData="queryTeachListData.list" :paginationData="queryTeachListData" :tableColumn="tableColumn1">
						<template v-slot:begin="{row}">
							<!-- <el-date-picker style="position: absolute;opacity: 0;" v-model="start_time"
								@change="updatebaseInfo(row,start_time,'beginTime')" type="datetime"
								:placeholder="$store.state.teachlanguagedata.select" format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions"
								value-format="timestamp" /> -->
							<div>{{row.beginTime | formatDate }}</div>
						</template>
						<template v-slot:end="{row}">
							<!-- <el-date-picker style="position: absolute;opacity: 0;" v-model="end_time"
								@change="updatebaseInfo(row,end_time,'endTime')" type="datetime"
								:placeholder="$store.state.teachlanguagedata.select" format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions"
								value-format="timestamp" /> -->
							<div>{{row.endTime | formatDate }}</div>
						</template>
						<template v-slot:caozhuo="{row}">
						  <div>
						    <el-button @click="ButtonMiddle(row,'1','ExplainMicro')">查看</el-button>
							<el-button @click="ButtonMiddle(row,'2')">下载</el-button>
							<el-button @click="ButtonMiddle(row,'3')">播放视频</el-button>
						  </div>
						</template>
					</BaseTable>
				</div>
				<div v-if="radio1=='视频列表'">
					<BaseTable :paginationData="TutorQueryListArry" @changeIndexOrPage="TutorQueryList"  :tableData="TutorQueryListArry.list" :tableColumn="tableColumn2">
						<template v-slot:begin="{row}">
							<!-- <el-date-picker style="position: absolute;opacity: 0;" v-model="start_time"
								@change="updatebaseInfo(row,start_time,'beginTime')" type="datetime"
								:placeholder="$store.state.teachlanguagedata.select" format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions"
								value-format="timestamp" /> -->
							<div>{{row.createTime | formatDate }}</div>
						</template>
						<template v-slot:caozhuo="{row}">
						  <div>
							<el-button @click="localStorageTeachvideo(row.fileType)">播放视频</el-button>
						  </div>
						</template>
					</BaseTable>
				</div>
			</div>
		</div>
		<!-- 发起笔记 -->
		<PublishJob ref="PublishJob" :CreateAiQuestionSetShow="CreateAiQuestionSetShow" :stageIndex="stageIndex"></PublishJob>
	</div>
</template>

<script>
	import Nav_class from '../../components/Nav_class'
	import BaseTable from '../../components/BasicComponents/BaseTable.vue'
	import {getPageWorks,updateBaseInfo,tutorQueryList,tasksave} from '../../api/base.js'
	import {queryTeachList} from '../../api/notebook.js'
	import PublishJob from '../../components/PublishJob/index.vue'
	export default {
		name: 'MicroClass',
		data() {
			return {
				stageIndex:1,
				CreateAiQuestionSetShow:4,
				planKind:'微课',
				radio1:'微课列表',
				radio2:'planId',
				tableCheck:'',
				addNoteShow:false,//发起笔记显示
				pickerOptions: {
					disabledDate(time) {
						return Date.now() - 8.64e7 >= time.getTime()
					}
				},
				start_time: '',
				end_time:'',
				queryTeachListData:{
					currPage:1,
					pageSize:10,
					list:[]
				},
				mainKindlist:[{name:'微课',mainKind:'planId'},{name:'题目',mainKind:'question'},{name:'知识点',mainKind:'keypoint'},{name:'解题方法',mainKind:'analyticMethod'},{name:'思维模型',mainKind:'thinkModel'}],
				tableColumn1:[{
				  prop: "name",
				  label: "名称",
				}, {
				  prop: "beginTime",
				  label: "开始时间",
				  slot: true,
				  slotName: 'begin'
				}, {
				  prop: "endTime",
				  label: "结束时间",
				  slot: true,
				  slotName: 'end'
				}, {
				  prop: "status",
				  label: "状态",
				}, {
				  prop: "id",
				  label: "操作",
				  slot: true,
				  slotName: 'caozhuo'
				}],
				tableColumn2:[{
				  prop: "mainName",
				  label: "名称",
				}, {
				  prop: "createTime",
				  label: "创建时间",
				  slot: true,
				  slotName: 'begin'
				}, {
				  prop: "id",
				  label: "操作",
				  slot: true,
				  slotName: 'caozhuo'
				}],
				TutorQueryListArry:{
					currPage:1,
					pageSize:10,
				},
			}
		},
		components: {
			Nav_class,BaseTable,PublishJob
		},
		watch: {
			radio1(val){
				if(val=='视频列表'){
					this.TutorQueryList()
				}
			},
			radio2(val){
				this.TutorQueryList()
			},
			addNoteShow(val){
				if(val){
					if(JSON.parse(localStorage.getItem('teachchapters')).length!=1){
						this.$message.error('教学章节有且只能选中一个')
						this.addNoteShow=false
					}
				}
			}
		},
		filters: { //配置过滤器
			formatDate: function(value) { //调用时间戳为日期显示
				let date = new Date(value)
				let y = date.getFullYear() //获取年份
				let m = date.getMonth() + 1 //获取月份
				m = m < 10 ? "0" + m : m //月份不满10天显示前加0
				let d = date.getDate() //获取日期
				d = d < 10 ? "0" + d : d //日期不满10天显示前加0
				//也可以获取更精准时间
				let h = date.getHours() //小时
				h = h < 10 ? "0" + h : h //月份不满10天显示前加0
				let mi = date.getMinutes() //分
				mi = mi < 10 ? "0" + mi : mi //月份不满10天显示前加0
				let s = date.getSeconds() //秒
				s = s < 10 ? "0" + s : s //月份不满10天显示前加0
				return y + "-" + m + "-" + d + " " + h + ":" + mi + ":" + s
			},
		},
		destroyed() {
			if(this.$refs.PointDataBgc&&this.$refs.PointDataBgc.getWebSocketcolse){
				this.$refs.PointDataBgc.getWebSocketcolse()
			}
		},
		create() {},
		mounted() {

		},
		methods: {
			//创建作业初始化
			init(){
				this.$refs.PublishJob.init()
			},
			TutorQueryList(canshu,zhixing){
				let data={
					subjectId:JSON.parse(localStorage.getItem('teachsubjectId')),
					fromId:JSON.parse(localStorage.getItem('teachschoolID')),
					currPage:this.TutorQueryListArry.currPage,
					pageSize:this.TutorQueryListArry.pageSize,
					mainKind:this.radio2
				}
				if(canshu){
					data=canshu
				}
				tutorQueryList(data).then(res=>{
					if(zhixing=='1'){
						this.localStorageTeachvideo(res.data.result.list[0].fileType)
					}else{
						this.TutorQueryListArry=res.data.result
						this.TutorQueryListArryShwo=false
					}
				})
			},
			localStorageTeachvideo(url){
				localStorage.setItem('teachvideo',JSON.stringify(url))
				this.routerPush('VideoPlayback')
			},
			updatebaseInfo(val1, val2, val3) {
				let data = {
					"id": val1.id,
					[val3]: val2
				}
				updateBaseInfo(data).then(res => {
					this.QueryTeachList()
				})
			},
			initialization(val) {
				this.queryTeachListData.currPage=1
				this.queryTeachListData.pageSize=10
				this.QueryTeachList()
			},
			//微课列表
			QueryTeachList(){
				let data={
					planKind: "微课",
					"chapterIds": JSON.parse(localStorage.getItem("teachchapters")),
					"userId": JSON.parse(localStorage.getItem("teachuserID")),
					"classId": JSON.parse(localStorage.getItem("teachclass_id")),
					currPage:this.queryTeachListData.currPage,
					pageSize:this.queryTeachListData.pageSize,
					textbookId:JSON.parse(localStorage.getItem('textbookId')),
					subjectId:JSON.parse(localStorage.getItem('teachsubjectId')),
				}
				getPageWorks(data).then(res=>{
					this.queryTeachListData=res.data.result
				})
			},
			ButtonMiddle(params,stutas,url){
				this.tableCheck=params
				localStorage.setItem('teachMicroClass',JSON.stringify(params))
				localStorage.setItem('teachNote',JSON.stringify(params))
				localStorage.setItem('teachQuestionSetInformation', JSON.stringify(params))
				if(stutas=='1'){
					this.go_to(url)
				}else if(stutas=='2'){
					let questionSetId=this.$Download.getQuestionSet(params.resourceRelationList,'questionSet')
					this.$Download.downloads(questionSetId)
				}else if(stutas=='3'){
					this.TutorQueryList({mainId:this.tableCheck.id,mainKind:'planId'},'1')
				}
			},
			go_to(url){
				this.routerPush(url)
			},
			//跳转页面 跳转地址 参数
			routerPush(url){
				this.$router.push({
					name: '' + url,
					params: {
								plan_id:this.tableCheck.id,
								
							}
				});
			},
		}
	}
</script>

<style>

</style>
